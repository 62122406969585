var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "primary lighten-1 font-weight-bold" },
        [_c("span", { staticClass: "text-h5" }, [_vm._v("Export List")])]
      ),
      _c(
        "v-card-text",
        { staticClass: "pb-0" },
        [
          _c("a", {
            ref: "exportLink",
            attrs: {
              download: `${_vm.filename}.csv`,
              href: _vm.blobUrl,
              hidden: "",
            },
          }),
          _vm.settings
            ? _c(
                "v-card",
                {
                  staticClass:
                    "dropHere d-flex justify-center align-center flex-column",
                  attrs: { color: "menu_background", dense: "", ripple: false },
                },
                [
                  [
                    _vm._t("settings", null, {
                      settingItems: _vm.asdf,
                      changeModel: _vm.changeModel,
                    }),
                  ],
                ],
                2
              )
            : _vm.preparingExport == false && _vm.unfinished.length > 0
            ? _c(
                "v-card",
                {
                  staticClass:
                    "dropHere d-flex flex-column justify-center align-center",
                  class: _vm.wrongInput ? "shake" : "",
                  attrs: { color: "menu_background", dense: "" },
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "defaultbg warning--text",
                      staticStyle: { "font-weight": "500" },
                    },
                    [_vm._v(" Export failed for some casinos: ")]
                  ),
                  _c("FailedItems", {
                    attrs: {
                      casinoList: _vm.casinoList,
                      unfinished: _vm.unfinished,
                    },
                    model: {
                      value: _vm.retry,
                      callback: function ($$v) {
                        _vm.retry = $$v
                      },
                      expression: "retry",
                    },
                  }),
                ],
                1
              )
            : _vm.preparingExport == false
            ? _c(
                "v-card",
                {
                  staticClass:
                    "dropHere d-flex flex-column justify-center align-center",
                  class: _vm.wrongInput ? "shake" : "",
                  attrs: { ripple: "", color: "menu_background", dense: "" },
                  on: { click: _vm.onClickExport },
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "primary--text my-4",
                      class: [_vm.download ? "download" : ""],
                      attrs: { size: "60" },
                    },
                    [_vm._v(" mdi-arrow-down-bold-circle ")]
                  ),
                  _c("p", [
                    _c("b", { staticClass: "primary--text" }, [
                      _vm._v("CLICK"),
                    ]),
                    _vm._v(" here to "),
                    _c("b", { staticClass: "primary--text" }, [
                      _vm._v("EXPORT"),
                    ]),
                    _vm._v(" list data. "),
                  ]),
                ],
                1
              )
            : _c(
                "v-card",
                {
                  staticClass:
                    "dropHere d-flex flex-column justify-center align-center",
                  class: _vm.wrongInput ? "shake" : "",
                  attrs: { color: "menu_background" },
                },
                [
                  _c("v-progress-circular", {
                    staticClass: "my-4",
                    attrs: {
                      indeterminate: _vm.indeterminate,
                      value: _vm.indeterminate ? 100 : _vm.progress,
                      color: _vm.exportNote.color
                        ? _vm.exportNote.color
                        : _vm.wrongInput
                        ? "error"
                        : "primary",
                      size: "50",
                    },
                  }),
                  _vm.wrongInput
                    ? _c("b", { staticClass: "error--text" }, [
                        _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
                      ])
                    : _c(
                        "p",
                        {
                          class: `${_vm.exportNote.color}--text`,
                          staticStyle: { "font-weight": "bold" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.exportNote.value ?? "Preparing your data"
                              ) +
                              " "
                          ),
                        ]
                      ),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "d-flex justify-space-between" },
        [
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(" Cancel ")]
          ),
          _vm.settings
            ? _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.disableExport,
                    text: "",
                    color: "primary",
                  },
                  on: { click: _vm.onSettingsDone },
                },
                [_vm._v(" Submit ")]
              )
            : _vm._e(),
          _vm.unfinished.length > 0 && _vm.preparingExport == false
            ? _c(
                "v-btn",
                {
                  attrs: { text: "", color: "warning" },
                  on: { click: _vm.onForceExport },
                },
                [_vm._v(" Export Anyway ")]
              )
            : _vm._e(),
          _vm.unfinished.length > 0 && _vm.preparingExport == false
            ? _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.retry.length === 0,
                    text: "",
                    color: "primary",
                  },
                  on: { click: _vm.onRetry },
                },
                [_vm._v(" Retry "), _c("v-icon", [_vm._v("mdi-refresh")])],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }