var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    {
      staticClass: "pb-0",
      staticStyle: {
        width: "100%",
        "max-height": "50vh",
        "overflow-y": "auto",
      },
    },
    [
      _c(
        "v-list-item-group",
        {
          attrs: { multiple: "" },
          model: {
            value: _vm._value,
            callback: function ($$v) {
              _vm._value = $$v
            },
            expression: "_value",
          },
        },
        _vm._l(_vm.unfinished, function (item, index) {
          return _c("v-list-item", {
            key: index,
            attrs: { value: item },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ active }) {
                    return [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("Capitalize")(
                                    _vm.casinoList.data.find(
                                      (el) => el.casino_id == item
                                    )?.website ?? "Unknown"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", [_vm._v("ID: " + _vm._s(item))]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-checkbox", {
                            attrs: { "input-value": active },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }