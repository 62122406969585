<template>
	<v-card>
		<v-card-title class="primary lighten-1 font-weight-bold">
			<span class="text-h5">Export List</span>
		</v-card-title>

		<v-card-text class="pb-0">
			<a
				ref="exportLink"
				:download="`${filename}.csv`"
				:href="blobUrl"
				hidden></a>
			<v-card
				v-if="settings"
				class="dropHere d-flex justify-center align-center flex-column"
				color="menu_background"
				dense
				:ripple="false">
				<template>
					<slot
						name="settings"
						:settingItems="asdf"
						:changeModel="changeModel"></slot>
				</template>
			</v-card>
			<v-card
				v-else-if="preparingExport == false && unfinished.length > 0"
				:class="wrongInput ? 'shake' : ''"
				class="dropHere d-flex flex-column justify-center align-center"
				color="menu_background"
				dense>
				<v-card-title
					class="defaultbg warning--text"
					style="font-weight: 500">
					Export failed for some casinos:
				</v-card-title>
				<FailedItems
					v-model="retry"
					:casinoList="casinoList"
					:unfinished="unfinished"></FailedItems>
			</v-card>
			<v-card
				v-else-if="preparingExport == false"
				ripple
				:class="wrongInput ? 'shake' : ''"
				class="dropHere d-flex flex-column justify-center align-center"
				color="menu_background"
				dense
				@click="onClickExport">
				<v-icon
					:class="[download ? 'download' : '']"
					class="primary--text my-4"
					size="60">
					mdi-arrow-down-bold-circle
				</v-icon>
				<p>
					<b class="primary--text">CLICK</b>
					here to
					<b class="primary--text">EXPORT</b>
					list data.
				</p>
			</v-card>
			<v-card
				v-else
				:class="wrongInput ? 'shake' : ''"
				color="menu_background"
				class="dropHere d-flex flex-column justify-center align-center">
				<v-progress-circular
					:indeterminate="indeterminate"
					:value="indeterminate ? 100 : progress"
					:color="
						exportNote.color
							? exportNote.color
							: wrongInput
							? 'error'
							: 'primary'
					"
					class="my-4"
					size="50"></v-progress-circular>
				<b
					v-if="wrongInput"
					class="error--text">
					{{ errorMessage }}
				</b>
				<p
					style="font-weight: bold"
					:class="`${exportNote.color}--text`"
					v-else>
					{{ exportNote.value ?? "Preparing your data" }}
				</p>
			</v-card>
		</v-card-text>
		<v-card-actions class="d-flex justify-space-between">
			<v-btn
				text
				@click="$emit('close')">
				Cancel
			</v-btn>
			<v-btn
				v-if="settings"
				:disabled="disableExport"
				text
				color="primary"
				@click="onSettingsDone">
				Submit
			</v-btn>
			<v-btn
				v-if="unfinished.length > 0 && preparingExport == false"
				text
				color="warning"
				@click="onForceExport">
				Export Anyway
			</v-btn>
			<v-btn
				v-if="unfinished.length > 0 && preparingExport == false"
				:disabled="retry.length === 0"
				text
				color="primary"
				@click="onRetry">
				Retry
				<v-icon>mdi-refresh</v-icon>
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import csvBuilderWorker from "worker-loader!../../script/csvBuilder.worker";
import FailedItems from "./FailedItems.vue";
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
	components: { FailedItems },
	props: {
		filename: {
			type: String,
			required: true,
		},
		builder: {
			type: String,
			required: false,
		},
		data: {
			type: [Array, Object],
			required: true,
		},
		showSettings: {
			type: Boolean,
			default: false,
		},
		indeterminate: {
			type: Boolean,
			default: false,
		},
		settingItems: {
			type: Array,
			default: () => [],
		},
		delimiter: {
			type: String,
			required: false,
			validator: (v) => [",", ";", ",", "\t"].includes(v),
		},
	},
	data() {
		return {
			unfinished: [],
			retry: [],

			exportNote: "",
			errorMessage: undefined,
			wrongInput: false,
			settings: this.showSettings,
			asdf: this.settingItems,
			progress: 0,
			settingModel: {},
			timeout: null,
			csvData: null,
			preparingExport: true,
			csvWorker: new csvBuilderWorker(),
			loading: false,
			download: false,
			disableExport: true,
		};
	},
	watch: {
		settingModel: {
			handler(newVal) {
				this.disableExport =
					Object.keys(newVal)
						.map((key) => newVal[key]?.length ?? 0)
						.filter((el) => el > 0).length == 0;
			},
			deep: true,
		},
		authToken: {
			handler() {
				this.onTokenUpdate();
			},
		},
	},
	computed: {
		...mapGetters(["casinoList"]),
		...mapGetters("apiCall", { authToken: "accessToken" }),
		blobUrl() {
			return (
				"data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(this.csvData)
			);
		},
	},

	methods: {
		onForceExport() {
			this.unfinished = [];
		},
		onTokenUpdate() {
			this.csvWorker.postMessage({
				event: "tokenUpdate",
				data: this.authToken,
			});
		},
		onAbort() {
			this.csvWorker.postMessage({
				event: "cancel",
			});
		},
		onRetry() {
			this.preparingExport = true;
			this.progress = 0;
			this.csvWorker.postMessage({
				event: "retry",
				data: { values: this.retry, token: this.authToken },
			});
		},
		changeModel(children, parent) {
			Vue.set(this.settingModel, parent, children);
		},
		onExportOpen() {
			this.preparingExport = true;
			this.csvWorker.postMessage({
				event: "createCSV",
				data: this.data,
				settings: this.settingModel,
				builder: this.builder,
				delimiter: this.delimiter,
			});
		},
		onSettingsDone() {
			this.settings = false;
			this.onExportOpen();
		},
		onClose() {
			clearTimeout(this.timeout);
			this.exportNote = { value: undefined, color: "warning" };
			this.progress = 0;
			this.preparingExport = true;
			this.download = false;
			this.settings = this.showSettings;
			this.onAbort();
			Vue.set(this, "settingModel", {});
		},
		onClickExport() {
			this.$refs.exportLink.click();
			this.download = true;
			setTimeout(() => {
				this.$emit("close");
			}, 1000);
		},
	},
	created() {
		this.preparingExport = true;
		this.progress = 0;
		this.csvWorker.addEventListener("message", (e) => {
			console.debug(e);
			if (e.data.status == "progress") {
				this.progress = e.data.value;
			}
			if (e.data.status == "partial") {
				this.progress = 100;
				this.csvData = e.data.res;
				this.retry = e.data.unfinished;
				this.exportNote = { value: undefined, color: "warning" };
				this.timeout = setTimeout(() => {
					this.progress = 0;
					this.preparingExport = false;
					this.unfinished = e.data.unfinished?.sort();
				}, 750);
			}
			if (e.data.status == "note") {
				this.exportNote = { value: e.data.value, color: e.data.color };
			}
			if (e.data.status === "done") {
				this.progress = 100;
				this.csvData = e.data.res;
				this.timeout = setTimeout(() => {
					this.progress = 0;
					this.preparingExport = false;
				}, 750);
			}
			if (e.data.status === "error") {
				this.wrongInput = true;
				this.progress = 0;
				this.errorMessage = e.data.error._val ?? "Export failed";
				this.$refs.exportLink.value = "";
				setTimeout(() => {
					this.wrongInput = false;
					this.$emit("close");
				}, 750);
			}
		});
	},
	beforeDestroy() {
		this.csvWorker.terminate();
	},
};
</script>
<style>
.dropHere {
	min-height: 40vh;
	border-radius: 20px;
}
.listTable {
	min-width: 90%;
}

.download {
	animation: download 1.3s ease-in-out;
}

.loading {
	animation: loading 2s ease-in-out;
	animation-fill-mode: forwards;
}

.shake {
	animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	transform: translate3d(0, 0, 0);
}

@keyframes loading {
	from {
		transform: translateY(0px);
		opacity: 1;
	}
	to {
		transform: translateY(-40px);
		opacity: 0;
	}
}

@keyframes download {
	from {
		transform: translateY(0px);
		opacity: 1;
	}
	to {
		transform: translateY(40px);
		opacity: 0;
	}
}

@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}
	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}
	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}
	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}
</style>
