<template>
	<v-list
		class="pb-0"
		style="width: 100%; max-height: 50vh; overflow-y: auto">
		<v-list-item-group
			v-model="_value"
			multiple>
			<v-list-item
				:value="item"
				v-for="(item, index) in unfinished"
				:key="index">
				<template v-slot:default="{ active }">
					<v-list-item-content>
						<v-list-item-title>
							{{
								casinoList.data.find((el) => el.casino_id == item)?.website ??
								"Unknown" | Capitalize
							}}
						</v-list-item-title>
						<div>ID: {{ item }}</div>
					</v-list-item-content>

					<v-list-item-action>
						<v-checkbox :input-value="active"></v-checkbox>
					</v-list-item-action>
				</template>
			</v-list-item>
		</v-list-item-group>
	</v-list>
</template>

<script>
export default {
	props: {
		unfinished: {
			type: Array,
			required: true,
		},
		casinoList: {
			type: Object,
			required: true,
		},
		value: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			retry: [],
		};
	},
	computed: {
		_value: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
	},
};
</script>
